/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans:300,400,500,600,700|Roboto:300,400,500,600,700");
// Global vendors
@import "~bootstrap/dist/css/bootstrap.css";
// => Material
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Global fonts
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/keenthemes-icons/font/ki.css";

// Metronic styles (replace these path when using RTL css below)
@import "./assets/sass/style.angular.scss";

// For RTL, check this documentation https://keenthemes.com/metronic/?page=docs&section=angular-rtl
// @import "./assets/sass/style.angular.rtl.css";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";

// iconos
@import "./assets/icomoon/style.css";

// Header themes
// .header-base-light {
//     @import "./assets/sass/themes/layout/header/base/light.scss";
// }

// .header-base-dark {
//     @import "./assets/sass/themes/layout/header/base/dark.scss";
// }

// Header Menu themes
// .header-menu-light {
//     @import "./assets/sass/themes/layout/header/menu/light.scss";
// }
// .header-menu-dark {
//     @import "./assets/sass/themes/layout/header/menu/dark.scss";
// }

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// .aside-dark {
//   @import "./assets/sass/themes/layout/aside/dark.scss";
// }

// .aside-light {
//   @import "./assets/sass/themes/layout/aside/light.scss";
// }

.pagination-right{
	position: relative;
	display: block;
	float: right;
}

.container-mod{
    position: relative !important;
    display: block !important;
    overflow: hidden !important;
    text-align: justify !important;
	width: 80%;
	margin: 0 auto;
	label{
		color: #003264;
		font-size: 12px;
	}
	textarea{
		width: 100%;
		border: 1px solid #aeafca;
		border-radius:7px;
		padding: 5px;
		color: #aeafca;
		font-size: 12px;
	}
	span{
		text-align: center !important;
		color: #aeafca;
		font-size: 11px !important;
		margin: 0 auto;
	}
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.angular-bootstrap-table {
  overflow-x: auto;
}
.angular-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead th.sortable-active {
    color: #003264 !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
	cursor: pointer !important;
  }

.progress-modal {
  height: 3px;
  width: 100%;
}

// Estilos generales

.titulo-principal{
    font-size: 40px;
    font-weight: normal;
}

.subtitulo{
  
}
.titulo-modal{
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 26px !important;
    line-height: 33px;
    display: block;
}
.sub-titulo-modal{
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 18px;
  color: #AEAFCA;
}
.btn-create{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #003264; 
}
.btn-create:hover{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #D3B574 !important;
  cursor:pointer;
}

.input_form{
  width: 311px !important;
  border-color: #003264;
  font-weight: 100;
  font-size: 16px;
  line-height: 24px;
  color: #003264;
}
.text-second{
  color: #999896 !important;
}

.text-highlight{
	color: #D3B574 !important;
  }
  .span-print-qr {
    background-color: black !important;
    color: #fff !important;
}
.formulario-input{
    height: 34px;
    border-color: #AEAFCA;
    font-weight: 100;
    font-size: 13px;
    line-height: 20px;
    color: #003264;
}

.formulario-input::placeholder{
	color: #AEAFCA
}

.w_400{
  width: 400px !important;
}
.table {
	thead {
		th, td {
			font-weight: bold !important;
			font-size: 1rem;
			border-bottom-width: 1px;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}
}
.acciones{
  text-align: center;
  font-weight: bold;
  color: #AEAFCA;
  font-size: 13px;
}
.menu-acciones{
  left: 40px !important;
  width: 160px !important;
  box-shadow: 0px 1px 4px rgb(0 63 128 / 35%);
  border-radius: 0px 8px 8px 8px !important;
}
// Estilos sweetalert
.swal2-title{
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 33px;
  color: #003264 !important;
}
.swal2-html-container{
  color: #AEAFCA !important;
}
.swal2-cancel{
  border: 1px solid #AEAFCA !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  color: #003264 !important;
  background: #fff !important;
}
.swal2-cancel:hover{
  border: 1px solid #003264 !important;
  box-sizing: border-box;
  border-radius: 5px !important;
  color: #fff !important;
  background: #003264 !important;
}
.swal2-confirm{
  border-radius: 5px !important;
  color: #fff !important;
  background: #003264 !important;
  margin-left: 15px !important;

}
.swal2-confirm:hover{
  border-radius: 5px !important;
  color: #003264 !important;
  background: #D3B574 !important; 
}
.swal2-icon.swal2-warning {
  border-color: #f5554859 !important;
  color: #F55548 !important;
}
.ngx-pagination .current {
  padding: 0.1870rem 0.625rem;
  background: #003264 !important;
  color: #fefefe;
  cursor: default;
  border-radius: 12px !important;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill){
	height: 32px;
    border-color: #AEAFCA;
    font-weight: 100;
    font-size: 13px;
    line-height: 20px;
    color: #003264;
}

.mat-form-field{
	display: block !important;
}

.mat-input-element { 
	margin-top: 5px ;
	border: solid 1px ;
	border-radius: 5px ;
}

.highlightOver:hover {
	border-left: solid #D3B574;
}

.highlightClicked:active{
	border: 1.2px solid #D3B574;
}

.text-third {
	color: #CCD1D1 !important;
  }
  
// Base
.wizard.wizard-1 {
	// Nav
	.wizard-nav {
		// Steps
		.wizard-steps {
			display: flex;
			justify-content: center;
			align-items: center;

			// Step
			.wizard-step {
				display: flex;
				align-items: center;
				text-align: center;

				.wizard-label {
					margin-left: 3.5rem;
					margin-right: 3.5rem;

					.wizard-icon {
						color: $text-muted;
						font-size: 2.25rem;

						@include svg-icon-color($text-muted);
					}

					.wizard-title {
						color: $dark-50;
						font-size: 0.85rem;
						font-weight: 500;
						margin-top: 0.75rem;
					}
				}

				.wizard-arrow {
					color: $dark-50;
					font-size: 1.25rem;

					&.last {
						display: none;
					}
				}

				&[data-wizard-state="done"] {
					.wizard-label {
						.wizard-icon {
							color: $primary;

							@include svg-icon-color($primary);
						}

						.wizard-title {
							color: $primary;
						}
					}
					.wizard-arrow {
						color: $primary;

						@include svg-icon-color($primary);
					}
				}
				&[data-wizard-state="current"] {
					.wizard-label {
						.wizard-icon {
							color: $primary-hover;

							@include svg-icon-color($primary-hover);
						}

						.wizard-title {
							color: $primary-hover;
						}
					}
					.wizard-arrow {
						color: $primary-hover;

						@include svg-icon-color($primary-hover);
					}
				}
			}
		}
	}
}

.columngap{
	-moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
}

// Desktop Mode
@include media-breakpoint-between(md, lg) {
	.wizard.wizard-1 {
		// Nav
		.wizard-nav {
			// Steps
			.wizard-steps {
				// Step
				.wizard-step {
					.wizard-label {
						margin-left: 1.5rem;
						margin-right: 1.5rem;

						.wizard-icon {
							font-size: 3.75rem;
						}

						.wizard-title {
							margin-top: 0.75rem;
						}
					}

					.wizard-arrow {
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}

// Table and Mobile mode
@include media-breakpoint-down(md) {
	.wizard.wizard-1 {
	}
}

// Mobile mode
@include media-breakpoint-down(sm) {
	.wizard.wizard-1 {
		// Nav
		.wizard-nav {
			// Steps
			.wizard-steps {
				flex-direction: column;

				// Step
				.wizard-step {
					width: 100%;
					flex-direction: row;
					justify-content: space-between;
					margin-bottom: 0.5rem;
					margin-top: 0.5rem;

					.wizard-label {
						flex-direction: row;
						display: flex;
						align-items: center;
						margin-left: 0;
						margin-right: 0;

						.wizard-icon {
							font-size: 1.5rem;
							margin-right: 1.25rem;
						}
					}

					.wizard-arrow {
						font-size: 1rem;

						&.last {
							display: block;
						}
					}
				}
			}
		}
	}
}

.cw-upload-file {
	border: 2px solid #D3B574;
	// height: 100%;
	border-style: solid;
	border-radius: 5px; 
	align-items: center;
	justify-content: center;
	position: relative;
  }

  .fix-content-img{
	  max-width: 100%;
	  max-height: 100%;
	  border-radius: 5px;
	  object-fit: contain;
  }

  .card-hv {
	  padding: 0; 
  }

  .nav-icons {
	  padding-bottom: 10px;
	  padding-top: 10px;
  }

  .mat-progress-bar-buffer {
    background-color: $text-muted !important;
}

.mat-progress-bar-fill::after {
    background-color: $primary-hover !important;
}

.inputfilehidden {
	width: 0px;
}

.no-margin {
	margin: 0px;
}

.no-padding {
	padding: 0px;;
}

.arrow-back{
    font-size: 40px;
    color: $primary-hover;
	cursor: pointer;
}
 
.img-wrap {
    position: relative; 
}
.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100; 
}

.delete-img-icon{
    border-radius: 90%;
    background-color: $primary-hover; 
    margin: -5%; 
    opacity: 100%;
	cursor: pointer;
	color: white;
	font-weight: 1px;
}

.fix-content-icon{
	max-width: 10%;
	max-height: 10%; 
	object-fit: contain;
}
.btn.btn-success {
    color: #ffffff;
    background-color: $primary !important;
    border-color: $primary !important;
}

.btn.btn-success:hover {
    color: #ffffff !important;
    background-color: $primary-hover !important;
    border-color: $primary-hover !important;
}

//  Style input file
.mat-form-field-infix{
	padding-top: 0;
}

.align-right {
	text-align: right;
}

.cursor-pointer {
	cursor: pointer;
}

.icon-hover:hover {
	color: $primary;
}

.maxImg{
    width: 100%;
    height: 118px;
    margin: 7px 0px 0px 0;
}
//  Style input file
.mat-form-field-infix{
	padding-top: 0;
}
#cke_1_bottom{
	display: none !important;
}
.puntero{
	cursor: pointer;
}
#cke_85_bottom{
	display: none;
}
.text-segundario{
	color: #D3B574 !important;
}
.cke_bottom{
	display: none;
}
#cke_109_bottom{
	display: none;
}
#cke_95_bottom{
	display: none;
}
#cke_1_top{
	display: none;
}

.btn-acceder{ 
	background: #003264 !important;
	border-radius: 10px;
	width: 311px;
	height: 48px;
	color: #fff;
	font-style: normal;
	font-weight: lighter;
	font-size: 20px !important;
	line-height: 24px;
  }
  .btn-acceder:hover{ 
	background: #D3B574 !important;
	border-radius: 10px;
	width: 311px;
	height: 48px;
	color: #003264; 
  }

input .money::before{ 
	content: "$"
}

.hoverPointer:hover{
	cursor: pointer;
}

.dropfile{
	padding:10px;
}
.dropfile input{
	display: none;
}
.dropfile label{
	border:1px solid #003264;
	border-radius: 5px;
	width:100%;
	height:10rem;
	display:flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: #AEAFCA;
}
.dropfile label:hover{
	cursor: pointer;
}

.loading {	
	height: 20px;
	width: 20px;
	border-top: 2px solid #003264;
	border-radius: 50%;
	animation: circular-rotation 1s ease 0s infinite normal;
}

@keyframes circular-rotation {
	0%   {transform: rotate(0deg)}
	100% {transform: rotate(360deg);}
}


